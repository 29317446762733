import { Button } from "antd";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  const { type } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const shopNewIn = () => {
    if (type === "Men")
      navigate(`/new-in/men/cid=${1}&name=new-in-men-products`);
    if (type === "Women")
      navigate(`/new-in/women/cid=${2}&name=new-in-women-products`);
  };

  return (
    <div className="banner-container">
      <img
        // src="https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1732096359/website-pictures-optimized/women-main-bg_etaexj.png"
        src="https://res.cloudinary.com/losode-ng/image/upload/v1744017773/website-pictures-optimized/women-homepage_hvifq0.png"
        alt="banner"
        className="bannercontainer-image"
        height={600}
        width={1800}
      />

      <div className="banner-content-container">
        <div className="banner-content-title">
          Empower Your Style with Unbeatable Savings
        </div>
        <div className="subtext">
          Shop massive savings from hundreds of top designers and find stylish,
          high-quality pieces at affordable prices. At Losode, we offer unique
          styles that help you stand out, from dresses to accessories and
          everything in between. Enjoy exclusive discounts and discover fashion
          that’s both on-trend and budget-friendly
        </div>
        <Button
          type={isTabletOrMobile ? "link" : "primary"}
          onClick={() => shopNewIn()}
          className="shop-now-btn"
        >
          <span
            style={{ textDecoration: isTabletOrMobile ? "underline" : "none" }}
          >
            Shop now
          </span>
        </Button>
      </div>
      <style jsx="true">{`
        .banner-container {
          position: relative;
          background-position: top;
        }
        .bannercontainer-image {
          width: 100%;
          height: 100%;
        }
        .banner-content-container {
          margin: 16px auto 0;
          width: 80%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          bottom: 0px;
          left: 0px;
          padding: 16px 16px 0;
        }
        .banner-content-container .banner-content-title {
          font-family: "DomaineSansText-Medium";
          font-size: 30px;
          text-align: center;
          line-height: 30px;
          margin: 8px auto;
        }
        .banner-content-container .subtext {
          font-size: 16px;
          font-family: DomaineSansText-Light;
          line-height: 1.4;
          max-width: 1100px;
          margin: 8px auto 16px;
        }

        .banner-content-container .shop-now-btn {
          color: white;
          font-family: "DomaineSansText-Light";
          padding: 0px;
          height: 48px;
          font-size: 16px;
          margin-top: 8px;
          text-transform: capitalize;
          width: 350px;
          border-radius: 2px;
        }

        @media (max-width: 640px) {
          .banner-container {
            height: 248px;
          }
          .banner-content-container {
            bottom: 0px;
            left: 0px;
            width: 100%;
            padding: 16px;
          }
          .banner-container .btn-shop-banner {
            width: 100%;
            height: 40px;
            font-size: 14px;
          }
          .banner-content-container .subtext {
            font-size: 14px;
          }
        }
      `}</style>
    </div>
  );
};

export default Banner;
