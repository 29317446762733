export const countryInfo = [
  {
    name: "United States of America",
    code: "USA",
    flag: "/images/icon-usa.png",
  },
  { name: "United Kingdom", code: "UK", flag: "/images/icon-gbp.png" },
  { name: "Ghana", code: "Ghana", flag: "/images/icon-gh.png" },
  { name: "Nigeria", code: "Nigeria", flag: "/images/icon-nga.png" },
  { name: "South Africa", code: "South Africa", flag: "/images/icon-sf.png" },
];

export const countries = [
  {
    code: "NGN",
    name: "Nigeria (NGN)",
    icon: "/images/icon-nga.png",
    country: "Nigeria",
    dial: "+234",
  },
  // {
  //   code: "USD",
  //   name: "American/USD",
  //   icon: "/images/icon-usa.png",
  //   country: "United States of America",
  //   dial: "+1",
  // },
  // {
  //   code: "GBP",
  //   name: "United Kingdom/GBP",
  //   icon: "/images/icon-gbp.png",
  //   country: "United Kingdom",
  //   dial: "+44",
  // },
  // {
  //   code: "EUR",
  //   name: "Europe/Euro",
  //   icon: "/images/icon-eur.png",
  //   country: "Scotland",
  // },
];

export const newCountries = [
  // {
  //   code: "NGN",
  //   name: "Nigeria (NGN)",
  //   icon: "/images/icon-nga.png",
  //   country: "Nigeria",
  //   dial: "+234",
  // },
  {
    code: "USD",
    name: "American/USD",
    icon: "/images/icon-usa.png",
    country: "United States",
    dial: "+1",
  },
  {
    code: "GBP",
    name: "United Kingdom/GBP",
    icon: "/images/icon-gbp.png",
    country: "United Kingdom",
    dial: "+44",
  },
  {
    code: "CED",
    name: "Europe/Euro",
    icon: "/images/icon-gh.png",
    country: "Ghana",
    dial: "+233",
  },
];

/*
35 Luther close, edgware
Edgware,
London,
HA88YY
United Kingdom

No 12, JK building road, anthony, Lagos
Maryland,
Lagos,
10001
Nigeria

1895 Barker Cypress
Houston,
Texas,
77084
United States

*/

export const planFeatures = [
  { name: "Session Time", plan1: "2 Hours", plan2: "2 Hours" },
  {
    name: "Number of Items",
    plan1: "12 Items",
    plan2: "12 Items",
  },
  { name: "Photoshoot Coordinator", plan1: "true", plan2: "true" },
  { name: "Professional Photographer", plan1: "true", plan2: "true" },
  { name: "Professional Models", plan1: "false", plan2: "true" },
  { name: "Professional Makeup Artist", plan1: "false", plan2: "true" },
  { name: "Studio Booking", plan1: "false", plan2: "true" },
];

export const plans = {
  plan2: {
    name: "Photoshoot 2",
    desc: "Clothing items",
    price: "175000",
    type: "plan2",
    features: [
      "2 Hour session time",
      "12 Outfits per Designer",
      "Photoshoot Coordinator",
      "Professional Photographer",
      "Professional Models",
      "Professional Makeup Artist",
      "Studio Booking",
    ],
  },
  plan1: {
    name: "Photoshoot 1",
    desc: "Non-Clothing items",
    price: "135000",
    type: "plan1",
    features: [
      "2 Hour session time",
      "20 (Non-clothing ) Items per designer",
      "Photoshoot Coordinator",
      "Professional Photographer",
    ],
  },
};

export const categoryOptions = [
  "Clothing",
  "Shoes",
  "Accessories",
  "Beauty",
  "Grooming",
];