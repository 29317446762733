import React from "react";
import { useNavigate } from "react-router-dom";



function FloatSellOnLosode() {
    const navigate = useNavigate();
  return (
    <div className="float-sell-on-losode">
      <div
        className="float-sell-on-losode-button"
        onClick={() => navigate("/sell")}
      >
        Sell on Losode
      </div>

      <style jsx="true">{`
        .float-sell-on-losode {
          position: fixed;
          color: white;
          top: 40%;
          right: 0;
          cursor: pointer;
          z-index: 2000;
        }
        .float-sell-on-losode-button {
          writing-mode: vertical-rl;
          background: #000000;
          padding: 15px 10px;
          width: 100%;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: "DomaineSansText-Regular";
          border-radius: 5px 0px 0px 5px;
        }

        @media (max-width: 640px) {
          .float-sell-on-losode {
            display: none;
            width: 0;
          }
          .float-sell-on-losode-button {
            display: none;
            width: 0;
            padding: 12px 8px;
            font-size: 12px;
          }
        }
      `}</style>
    </div>
  );
}

export default FloatSellOnLosode;
